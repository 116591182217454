<template>
  <div>
    <spinner-loader :loading="loading" />
    <azkar-category-form
      v-if="!loading"
      :item="azkarCategory"
      :submit="editAzkarCategory"
    />
  </div>
</template>
<script>
import AzkarCategoryForm from '@/common/components/azkar/AzkarCategoryForm.vue'
import AzkarCategory from '@/common/compositions/azkar/AzkarCategoryApi'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import SpinnerLoader from '@/common/components/common/Table/SpinnerLoader.vue'

export default {
  name: 'EditZikrCategory',
  components: { AzkarCategoryForm, SpinnerLoader },
  data() {
    return {
      loading: true,
    }
  },
  setup() {
    const {
      azkarCategory, getazkarCategoryRequest, editazkarCategoryRequest, prepareFormData,
    } = AzkarCategory()
    const { successfulOperationAlert } = handleAlerts()
    return {
      azkarCategory, getazkarCategoryRequest, editazkarCategoryRequest, prepareFormData, successfulOperationAlert,
    }
  },
  created() {
    this.getazkarCategoryRequest(this.$route.params.id).then(res => {
      this.azkarCategory = {
        ...res.data.data,
        image: [res.data.data.icon],
      }
    }).finally(() => {
      this.loading = false
    })
  },
  methods: {
    editAzkarCategory() {
      this.prepareFormData()
      return this.editazkarCategoryRequest(this.$route.params.id).then(() => {
        this.successfulOperationAlert('Category is updated successfully')
        this.$router.push({ name: 'azkarCategory-list' })
      })
    },
  },
}
</script>
<style lang="">

</style>
